export default {
  // Trình độ của người lao động nước ngoài
  foreignerEducationLevel: [
    {
      name: 'Chứng chỉ đào tạo',
      key: 'TrainingCertification',
    },
    {
      name: 'Đại học',
      key: 'University',
    },
    {
      name: 'Thạc sĩ',
      key: 'Master',
    },
    {
      name: 'Tiến sĩ',
      key: 'Doctor',
    },
    {
      name: 'Chứng chỉ hành nghề',
      key: 'PracticingCertification',
    },
  ],
  // Enum trình độ chuyên môn người lao động nước ngoài
  foreignerQualification: [
    {
      name: 'Khoa học giáo dục và đào tạo giáo viên',
      key: 'Qualification1',
    },
    {
      name: 'Nhân văn',
      key: 'Qualification2',
    },
    {
      name: 'Kinh doanh và quản lý',
      key: 'Qualification3',
    },
    {
      name: 'Khoa học sự sống, sinh học',
      key: 'Qualification4',
    },
    {
      name: 'Toán và thống kê',
      key: 'Qualification5',
    },
    {
      name: 'Công nghệ kỹ thuật',
      key: 'Qualification6',
    },
    {
      name: 'Sản xuất và chế biến',
      key: 'Qualification7',
    },
    {
      name: 'Xây dựng',
      key: 'Qualification8',
    },
    {
      name: 'Thú y',
      key: 'Qualification9',
    },
    {
      name: 'Dịch vụ xã hội',
      key: 'Qualification10',
    },
    {
      name: 'Dịch vụ vận tải',
      key: 'Qualification11',
    },
    {
      name: 'An ninh, Quốc phòng',
      key: 'Qualification12',
    },
    {
      name: 'Nghệ thuật',
      key: 'Qualification13',
    },
    {
      name: 'Báo chí và thông tin',
      key: 'Qualification14',
    },
    {
      name: 'Pháp luật',
      key: 'Qualification15',
    },
    {
      name: 'Khoa học tự nhiên',
      key: 'Qualification16',
    },
    {
      name: 'Máy tính và công nghệ thông tin',
      key: 'Qualification17',
    },
    {
      name: 'Kỹ thuật',
      key: 'Qualification18',
    },
    {
      name: 'Kiến trúc và xây dựng',
      key: 'Qualification19',
    },
    {
      name: 'Nông, lâm nghiệp và thủy sản',
      key: 'Qualification20',
    },
    {
      name: 'Sức khoẻ',
      key: 'Qualification21',
    },
    {
      name: 'Du lịch, khách sạn, thể thao và dịch vụ cá nhân',
      key: 'Qualification22',
    },
    {
      name: 'Môi trường và bảo vệ môi trường',
      key: 'Qualification23',
    },
    {
      name: 'Khác',
      key: 'Qualification24',
    },
  ],
  // Enum vị trí công việc người lao động nước ngoài
  foreignerJobPosition: [
    {
      name: 'Nhà quản lý',
      key: 'Manager',
    },
    {
      name: 'Giám đốc điều hành',
      key: 'CEO',
    },
    {
      name: 'Chuyên gia',
      key: 'Specialized',
    },
    {
      name: ' Lao động kỹ thuật',
      key: 'Worker',
    },
  ],
  // Enum nghề của người lao động nước ngoài
  foreignerJob: [
    {
      name: 'Nhà chuyên môn, kỹ thuật viên khoa học và kỹ thuật',
      key: 'Job1',
    },
    {
      name: 'Nhà chuyên môn, kỹ thuật viên về sức khỏe',
      key: 'Job2',
    },
    {
      name: 'Nhà chuyên môn, giáo viên giảng dạy',
      key: 'Job3',
    },
    {
      name: 'Nhà chuyên môn, nhân viên về kinh doanh và quản lý',
      key: 'Job4',
    },
    {
      name: 'Nhà chuyên môn, kỹ thuật trong lĩnh vực công nghệ thông tin và truyền thông',
      key: 'Job5',
    },
    {
      name: 'Nhà chuyên môn, nhân viên về pháp luật, văn hóa, xã hội',
      key: 'Job6',
    },
    {
      name: 'Lao động trong khai khoáng, xây dựng, công nghiệp chế biến, chế tạo và giao thông vận tải',
      key: 'Job7',
    },
    {
      name: 'Nhân viên tổng hợp, văn phòng và các công việc bàn giấy',
      key: 'Job8',
    },
    {
      name: 'Lao động kỹ thuật trong nông nghiệp, lâm nghiệp và thủy sản',
      key: 'Job9',
    },
    {
      name: 'Lao động kỹ thuật trong xây dựng, luyện kim, cơ khí',
      key: 'Job10',
    },
    {
      name: 'Thợ điện, điện tử, chế biến thực phẩm, gỗ, may mặc, đồ thủ công',
      key: 'Job11',
    },
    {
      name: 'Thợ lắp ráp, vận hành máy móc và thiết bị',
      key: 'Job12',
    },
    {
      name: 'Khác',
      key: 'Job13',
    },
  ],
  // Enum hình thức làm việc
  foreignerForm: [
    {
      name: 'Thực hiện hợp đồng lao động',
      key: 'Form1',
    },
    {
      name: 'Di chuyển trong nội bộ doanh nghiệp',
      key: 'Form2',
    },
    {
      name: 'Thực hiện các loại hợp đồng hoặc thỏa thuận',
      key: 'Form3',
    },
    {
      name: 'Làm việc cho tổ chức phi chính phủ nước ngoài, tổ chức quốc tế tại Việt Nam',
      key: 'Form4',
    },
    {
      name: 'Nhà quản lý, giám đốc điều hành, chuyên gia, lao động kỹ thuật',
      key: 'Form5',
    },
    {
      name: 'Tham gia thực hiện các gói thầu, dự án tại Việt Nam',
      key: 'Form6',
    },
    {
      name: 'Khác',
      key: 'Form7',
    },
  ],
  // Enum thẻ tạm trú hay visa
  foreignVISA: [
    {
      name: 'Thẻ tạm trú',
      key: 'TRC',
    },
    {
      name: 'VISA',
      key: 'VISA',
    },
  ],
  // Enum ký hiệu visa
  foreignVISAType: [
    {
      name: 'NG1',
      key: 'NG1',
    },
    {
      name: 'NG2',
      key: 'NG2',
    },
    {
      name: 'NG3',
      key: 'NG3',
    },
    {
      name: 'NG4',
      key: 'NG4',
    },
    {
      name: 'LV1',
      key: 'LV1',
    },
    {
      name: 'LV2',
      key: 'LV2',
    },
    {
      name: 'ĐT',
      key: 'ĐT',
    },
    {
      name: 'DN',
      key: 'DN',
    },
    {
      name: 'NN1',
      key: 'NN1',
    },
    {
      name: 'NN2',
      key: 'NN2',
    },
    {
      name: 'NN3',
      key: 'NN3',
    },
    {
      name: 'DH',
      key: 'DH',
    },
    {
      name: 'HN',
      key: 'HN',
    },
    {
      name: 'PV1',
      key: 'PV1',
    },
    {
      name: 'PV2',
      key: 'PV2',
    },
    {
      name: 'LĐ',
      key: 'LĐ',
    },
    {
      name: 'DL',
      key: 'DL',
    },
    {
      name: 'TT',
      key: 'TT',
    },
    {
      name: 'VR',
      key: 'VR',
    },
    {
      name: 'SQ',
      key: 'SQ',
    },
  ],
  // Cấp giấy phép/ giấy xác nhận
  workerLicenseType: [
    {
      name: 'Giấy phép',
      key: 'License',
    },
    {
      name: 'Giấy xác nhận',
      key: 'Confirm',
    },
  ],
}
