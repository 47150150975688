<template>
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="xl"
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <my-tabs
        :listTabs="listTabs"
        defaultActive="user-edit-tab-account"
        typeCss="active-full"
        @setActive="setActive"
      >
      </my-tabs>
      <validation-observer ref="rule">
        <InformationAll
          v-if="isRendered('general')"
          id="general"
          :type="type"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='general'}"
        />
        <InformationWorker
          v-if="isRendered('worker')"
          id="worker"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{active:activeTab==='worker'}"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'

export default {
  components: {
    BModal,
    ValidationObserver,
    MyTabs,
    InformationAll: () => import('./InformationAll.vue'),
    InformationWorker: () => import('./InformationWorker.vue'),
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin chung',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'worker',
          title: 'Thông tin về việc làm',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
      ],
      dataGeneral: {
        fullName: '',
        gender: true,
        birthDay: null,
        identityCardNumber: '',
        datessued: null,
        countryId: 0,
        country: 'string',
        foreignerEducationLevel: 'TrainingCertification',
        foreignerQualification: 'Qualification1',
        licenseNumber: 'string',
        dateLicense: null,
        bussiness: '',
        bussinessCode: '',
        bussinessAddress: '',
        bussinessType: '',
        foreignerJobPosition: 'Manager',
        foreignerJob: 'Job1',
        foreignerForm: 'Form1',
        dateStart: null,
        dateEnd: null,
        dateCollect: null,
        code: '',
        locationIssued: '',
        dateExpired: null,
        salary: 0,
        foreignVISA: 'TRC',
        codeForeignVISA: '',
        dateExpiredVISA: '',
        foreignVISAType: 'NG1',
        bussinessId: '',
        workerLicenseType: 'License',
        workerLicenseNote: '',
        revokeLicense: '',
        dateRevoke: null,
        noteRevoke: '',
        isRenew: true,
        reason: '',
      },
    }
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    async dataDetail(val) {
      if (val && this.type === 'edit') {
        this.dataGeneral = {
          fullName: this.dataDetail.fullName,
          gender: this.dataDetail.gender,
          birthDay: this.dataDetail.birthDay === '0001-01-01T00:00:00' ? '' : this.dataDetail.birthDay,
          identityCardNumber: this.dataDetail.identityCardNumber,
          datessued: this.dataDetail.datessued === '0001-01-01T00:00:00' ? '' : this.dataDetail.datessued,
          countryId: this.dataDetail.countryId,
          country: this.dataDetail.country,
          foreignerEducationLevel: this.dataDetail.foreignerEducationLevel,
          foreignerQualification: this.dataDetail.foreignerQualification,
          licenseNumber: this.dataDetail.licenseNumber,
          dateLicense: this.dataDetail.dateLicense === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateLicense,
          bussiness: this.dataDetail.bussiness,
          bussinessCode: this.dataDetail.bussinessCode,
          bussinessAddress: this.dataDetail.bussinessAddress,
          bussinessType: this.dataDetail.bussinessType,
          foreignerJobPosition: this.dataDetail.foreignerJobPosition,
          foreignerJob: this.dataDetail.foreignerJob,
          foreignerForm: this.dataDetail.foreignerForm,
          dateStart: this.dataDetail.dateStart === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateStart,
          dateEnd: this.dataDetail.dateEnd === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateEnd,
          dateCollect: this.dataDetail.dateCollect === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateCollect,
          code: this.dataDetail.code,
          locationIssued: this.dataDetail.locationIssued,
          dateExpired: this.dataDetail.dateExpired === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateExpired,
          salary: this.dataDetail.salary,
          foreignVISA: this.dataDetail.foreignVISA,
          codeForeignVISA: this.dataDetail.codeForeignVISA,
          dateExpiredVISA: this.dataDetail.dateExpiredVISA === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateExpiredVISA,
          foreignVISAType: this.dataDetail.foreignVISAType,
          bussinessId: this.dataDetail.bussinessId,
          workerLicenseType: this.dataDetail.workerLicenseType,
          workerLicenseNote: this.dataDetail.workerLicenseNote,
          revokeLicense: this.dataDetail.revokeLicense,
          dateRevoke: this.dataDetail.dateRevoke === '0001-01-01T00:00:00' ? '' : this.dataDetail.dateRevoke,
          noteRevoke: this.dataDetail.noteRevoke,
          isRenew: this.dataDetail.isRenew,
          reason: this.dataDetail.reason,
        }
      }
    },

    // type() {
    //   if (this.type === 'edit') {
    //     this.listTabs[1].isDisabled = false
    //   }
    //   if (this.type === 'add') {
    //     this.listTabs[1].isDisabled = true
    //   }
    // },
    activeTab(val) {
      if (val === null) {
        this.activeTab = 'general'
        this.listTabs[0].isRendered = true
      }
    },
  },

  async created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
    }
  },
  methods: {

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },

    accept(bvModalEvt) {
      const model = {
        ...this.dataGeneral,
        numberWorker: Number(this.dataGeneral.numberWorker),
      }
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataGeneral)
        }
      })
    },

    resetData() {
      this.activeTab = null
      this.dataGeneral = {
        fullName: '',
        gender: true,
        birthDay: '',
        identityCardNumber: '',
        datessued: '',
        countryId: 0,
        country: 'string',
        foreignerEducationLevel: '',
        foreignerQualification: '',
        licenseNumber: 'string',
        dateLicense: '',
        foreignerJobPosition: '',
        foreignerJob: '',
        foreignerForm: '',
        dateStart: '',
        dateEnd: '',
        dateCollect: '',
        code: '',
        locationIssued: '',
        dateExpired: '',
        salary: 0,
        foreignVISA: '',
        codeForeignVISA: '',
        dateExpiredVISA: '',
        foreignVISAType: '',
        bussinessId: '',
        workerLicenseType: '',
        workerLicenseNote: '',
        revokeLicense: '',
        dateRevoke: '',
        noteRevoke: '',
        isRenew: true,
        reason: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
